import axios from '@/libs/axios'

const accessToken = localStorage.getItem('accessToken')
let formJson = 'application/json'
let formData = 'multipart/form-data'

export default {
  save({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(payloads.path, payloads.formdata, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': (payloads.type === 'form-data' ? formData : formJson)
        },
      }).then(response => {
        if (response.status === 200) {
          commit('ADD_ITEM', Object.assign(response.data.data, { slug: response.data.data.slug }))
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        console.log('rejected from catch')
        if (error.response) {
          const { data } = error.response
          reject(data)
        } else {
          reject(error)
        }
      })
    })
  },
  edit({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(`${payloads.path}`, payloads.formdata, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': (payloads.type === 'form-data' ? formData : formJson)
        },
      }).then(response => {
        if (response.status === 200) {
          commit('UPDATE_ITEM', response.data.data)
          resolve(response.data.data)
        } else {
          reject(response.data)
        }
      }).catch(error => {
        console.log(error)
        if (error.response) {
          const { data } = error.response

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  importItem({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.post(`${payloads.path}`, payloads.formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(response => {
        // commit('ADD_ITEM', Object.assign(response.data.data, {slug: response.data.data.slug}));
        resolve(response.data.data)
      }).catch(error => {
        if (error.response) {
          const { data } = error.response

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetch({ commit }, paths) {
    return new Promise((resolve, reject) => {
      axios.get(`${paths}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          commit('SET_LIST', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          commit('SET_LIST', [])
          reject(error)
        })
    })
  },
  fetchFile({ commit }, paths) {
    return new Promise((resolve, reject) => {
      axios.get(`${paths}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          commit('SET_FILE', response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          console.log(error)
          commit('SET_FILE', '')
          reject(error)
        })
    })
  },
  remove({ commit }, payloads) {
    return new Promise((resolve, reject) => {
      axios.delete(`${payloads.path}&slug=${payloads.item}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(response => {
        commit('REMOVE_ITEM', payloads.item)
        resolve(response)
      }).catch(error => {
        if (error.response) {
          const { data } = error.response

          reject(data.data)
        } else {
          reject(error)
        }
      })
    })
  },
  fetchItem(context, payloads) {
    return new Promise((resolve, reject) => {
      axios.get(`/${payloads.path}/${payloads.slug}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        })
        .catch(error => { reject(error) })
    })
  },
}
